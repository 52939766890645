// import ProgramCutators from "../../components/program_curtators/ProgramCurtators";
// import AboutUs from "../../components/about-sec-one/AboutUs";
// import OurStoryNew from "../../components/our-story-new/OurStoryNew";
// import WhatDiffNew from "../../components/what-diff-new/WhatDiff";
// import WhatDriveUs from "../../components/what-drive-us/WhatDriveUs";
// import { appLevelConstants } from "../../constants/AppLevelConstants";

// export default function About() {

//   return (
//     <>
//       <AboutUs />
//       <WhatDriveUs />
//       <WhatDiffNew
//         data={appLevelConstants.aboutUs.whatDifference}
//         title="What Differentiates Iron Lady"
//         buttonId='WhatDiff_AboutUs'
//       />
//       <OurStoryNew />
//       <ProgramCutators />
//     </>
//   );
// }

import ProgramCutators from "../../components/program_curtators/ProgramCurtators";
import AboutUs from "../../components/about-sec-one/AboutUs";
import OurStoryNew from "../../components/our-story-new/OurStoryNew";
import WhatDiffNew from "../../components/what-diff-new/WhatDiff";
import WhatDriveUs from "../../components/what-drive-us/WhatDriveUs";
import { appLevelConstants } from "../../constants/AppLevelConstants";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";

export default function About() {
  const location = useLocation();
  const programCurtatorsRef = useRef(null);

  useEffect(() => {
    if (location.state?.scrollTo === 'programCurtators') {
      const timer = setTimeout(() => {
        programCurtatorsRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [location]);
  return (
    <>
      <AboutUs />
      <WhatDriveUs />
      <WhatDiffNew
        data={appLevelConstants.aboutUs.whatDifference}
        title="What Differentiates Iron Lady"
        buttonId='WhatDiff_AboutUs'
      />
      <OurStoryNew />
      <ProgramCutators  ref={programCurtatorsRef}
        scrollToId={location.state?.itemId} />
    </>
  );
}

