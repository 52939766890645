import React from 'react';
 import Testimonials from '../components/common_components/testimonials-program/Testimonials';
import TrustedWomens from "../components/trusted-womens/TrustedWomens";
import SuggestPrograms from "../components/suggest-programs/SuggestPrograms"
import ProgramInformation from '../components/program_information/ProgramInformation';
import ProgramCurtators from '../components/program_curtators/ProgramCurtators';
import { appLevelConstants } from '../constants/AppLevelConstants';
import { useRef } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Program() {
  const programInfoRef = useRef();

  const handleSucess = (messageGIT) => {
    toast.success(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose:5000,
      hideProgressBar: false,
      newestOnTop:false,
      rtl: false,
      containerId : 'getInTouch'
    });
  };
  const handleError = (messageGIT) => {
    toast.error(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose:5000,
      hideProgressBar: false,
      newestOnTop:false,
      rtl: false,
      containerId : 'getInTouch'
    });
  };

  return (
    <>
    {/* <ProgramHome callChild={callChild} /> */}
    <ProgramInformation ref={programInfoRef} />
    <Testimonials data = {appLevelConstants.testimonialsProgram} title="What Our Participants Say" buttonId='testimonials_Programs'/>
    <SuggestPrograms handleSucess={handleSucess} handleError={handleError} buttonId='suggestedPrograms_Programs'/>
    <TrustedWomens />
    <ProgramCurtators/>
    <ToastContainer limit={1}
          position="bottom-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          containerId= {'getInTouch'}
          enableMultiContainer
        />
    </> 
  );
}

export default Program;

