import Landing from "../components/landing/Landing";
import Testimonials from "../components/common_components/testimonials-program/Testimonials";
import SuggestPrograms from "../components/suggest-programs/SuggestPrograms";
import BreakthroughPrograms from "../components/breakthrough-programs/BreakthroughPrograms";
import Leadership from "../components/leadership-courses/LeadershipCourses";
import TrustedWomens from "../components/trusted-womens/TrustedWomens";
import EventRegistration from "../components/events-registration/EventRegistration";
import { appLevelConstants } from "../constants/AppLevelConstants";
import WhatDiffNew from "../components/what-diff-new/WhatDiff";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Glimpses from "../components/glimpses/Glimpses";
import React, { useRef } from "react";
import Review from "../components/google-review/Review";

export default function Home() {
  const sectionRef = useRef(null);

  const handleSucess = (messageGIT) => {
    toast.success(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: 'getInTouch'
    });
  };
  const handleError = (messageGIT) => {
    toast.error(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: 'getInTouch'
    });
  };

  return (
    <>
      <Landing handleSucess={handleSucess} handleError={handleError} />
      <EventRegistration />
      <div ref={sectionRef} id="section2">
        <WhatDiffNew data={appLevelConstants.aboutUs.whatDifference} title="Why Choose Iron Lady" buttonId='WhatDiff_Home' handleSucess={handleSucess} handleError={handleError} />
      </div>

      <Leadership />
      <TrustedWomens />
      <Testimonials data={appLevelConstants.testimonialsHome} title="Voice of Iron Ladies" buttonId='testimonials_Home' />
      <SuggestPrograms handleSucess={handleSucess} handleError={handleError} buttonId='suggestedPrograms_Home'></SuggestPrograms>
      <BreakthroughPrograms handleSucess={handleSucess} handleError={handleError}></BreakthroughPrograms>
      <Glimpses />
      <Review />
      <ToastContainer limit={1}
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId={'getInTouch'}
        enableMultiContainer
      />
    </>
  );
}
