import IndividualProgramsQuestions from "../components/individual_programs_questions/IndividualProgramsQuestions";
import { ThreeCircles } from "react-loader-spinner";
import SuggestPrograms from "../components/suggest-programs/SuggestPrograms";
import ProgramCurtators from "../components/program_curtators/ProgramCurtators";
import ProgramMain from "../components/program_main/ProgramMain";
import MethodologyNew from "../components/methodology-new/MethodologyNew";
import ProgramForYou from "../components/program-for-you/ProgramForYou";
import ProgramCurriculum from "../components/program-carriculum/ProgramCurriculum";
import ProgramOutcome from "../components/program-outcome/ProgramOutcome";
import Testimonials from "../components/common_components/testimonials-program/Testimonials";
import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import PriceStructure from "../components/price-structure/PriceStructure";
import { fetchIndividualPrograms } from "../service/individualProgramsDataFetchService";

export default function IndividualPrograms() {
  const { programName, language } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [bookMySpot, setBookMySpot] = useState(false);

  const testimonialsButtonId = "testimonials_" + programName;
  const SuggestedProgramsButtonId = "suggestedPrograms_" + programName;
  const applyForProgramButtonId = "applyForProgram_" + programName;

  // const fetchIndividualProgramData = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await fetchIndividualPrograms();
  //     const individualProgramDataFromApi = response.data.data.individual_program;
  //     if (individualProgramDataFromApi) {
  //       setFilteredData(
  //         individualProgramDataFromApi.find(
  //           (item) => item.title.split(" ").join("_") === programName
  //         )
  //       );
  //     }
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setIsLoading(false);
  //   }
  // };

  const fetchIndividualProgramData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchIndividualPrograms();
      const data = response.data.data;

      let programData;

      if (language === "hindi") {
        const hindiProgramData = data.individual_program_hindi?.find(
          (item) => item.title.split(" ").join("_") === programName
        );

        if (hindiProgramData) {
          programData = hindiProgramData;
        } else {
          console.warn("Hindi content not available for this program, defaulting to English.");
          programData = data.individual_program.find(
            (item) => item.title.split(" ").join("_") === programName
          );
        }
      } else {
        programData = data.individual_program.find(
          (item) => item.title.split(" ").join("_") === programName
        );
      }

      setFilteredData(programData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching program data:", error);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fetchIndividualProgramData();
  }, [programName]); // eslint-disable-line react-hooks/exhaustive-deps


  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const sectionPosition = sectionRef.current.getBoundingClientRect().top;

        if (sectionPosition <= window.innerHeight) {
          setBookMySpot(true);
        } else {
          setBookMySpot(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSucess = (messageGIT) => {
    toast.success(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };
  const handleError = (messageGIT) => {
    toast.error(messageGIT, {
      position: toast.POSITION.TOP_RIGHT,
      limit: 1,
      autoClose: 5000,
      hideProgressBar: false,
      newestOnTop: false,
      rtl: false,
      containerId: "getInTouch",
    });
  };

  return (
    <>
      {isLoading ? (
        <ThreeCircles
          height="70"
          width="70"
          color="#F52929"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "50vh",
          }}
          wrapperClass=""
          visible={true}
          ariaLabel="three-circles-rotating"
        />
      ) : (
        <>
          <ProgramMain
            bookMySpot={bookMySpot}
            handleSucess={handleSucess}
            handleError={handleError}
            buttonId={applyForProgramButtonId}
            filteredData={filteredData}
          />
          <ProgramForYou filteredData={filteredData} />
          <div ref={sectionRef} id="section2">
            <MethodologyNew filteredData={filteredData} />
          </div>
          <ProgramCurriculum filteredData={filteredData} />
          <ProgramOutcome filteredData={filteredData} />
          <IndividualProgramsQuestions filteredData={filteredData} />
          {filteredData?.programFee.showFeeSection && (
            <PriceStructure
              handleSucess={handleSucess}
              handleError={handleError}
              filteredData={filteredData}
            />
          )}
          <SuggestPrograms
            buttonId={SuggestedProgramsButtonId}
            handleSucess={handleSucess}
            handleError={handleError}
          />
          <Testimonials
            title={filteredData?.testimonials?.title}
            data={filteredData?.testimonials?.testimonials}
            buttonId={testimonialsButtonId}
          />
          <ProgramCurtators />
        </>
      )}

      <ToastContainer
        limit={1}
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        containerId={"getInTouch"}
        enableMultiContainer
      />
    </>
  );
}
