import React from 'react';
import Styles from './Scholarship.module.scss';
import { appLevelConstants } from '../../constants/AppLevelConstants';
import ScholarshipTestimonial from '../common_components/testimonials-scholarship/ScholarshipTestimonial';
import { FaAward } from "react-icons/fa";
import { FaTrophy } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";
import { FaChild } from "react-icons/fa";
import { FaHandsHelping } from "react-icons/fa";
import { FaHandHoldingUsd } from "react-icons/fa";
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ScholarshipCarousel from '../scholarship-carousel/ScholarshipCarousel';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.5rem', 'color': '#f52929' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Scholarship = () => {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div className={Styles["scholarship"]}>
            <ScholarshipCarousel />
            <h1 className={Styles["headline"]}>Empower a Woman. Shape the Future.</h1>
            <h2 className={Styles["subheadline"]}>Sponsor a woman's leadership journey or apply for a scholarship to unlock the door to success.</h2>

            <div className={Styles["section1"]}>
                <span>How Does the Pay It Forward Work?</span>
                <p>
                    Create Change with Iron Lady, we believe that every woman deserves the opportunity to lead with confidence. Through our Pay It Forward scholarship program, we are committed to supporting women who aspire to grow but face financial obstacles.
                </p>

                <span>Fuel the Future of Women in Leadership</span>

                <p>
                    Empower another woman to break barriers and rise to her full potential by contributing to the Pay It Forward initiative. Whether your contribution is big or small, the impact is transformative. Be the force behind another success story.
                </p>
            </div>

            <div className={Styles["card-component"]}>
                <h1 className={Styles["main-heading"]}>Sponsor a Woman's Journey</h1>

                <div className={Styles["sub-section"]}>
                    <p className={Styles["sub-paragraph"]}>By sponsoring a woman's scholarship, you become a partner in her success. Your contribution directly funds leadership training, enabling women to gain the skills and confidence to lead in the workplace and beyond.</p>
                </div>

                <div className={Styles["sub-section"]}>
                    <p className={Styles["sub-paragraph"]} style={{ 'color': '#f52929' }}>Sponsorship Options:</p>
                </div>

                <div className={Styles["card-container"]}>
                    <div className={Styles["card"]}>
                        <FaAward className={Styles["card-icon"]} />
                        <p className={Styles["card-description"]}>Part Scholarship <br /> ₹ 15,000 <br /> <br /> Help one woman take the first step</p>
                    </div>

                    <div className={Styles["card"]}>
                        <FaTrophy className={Styles["card-icon"]} />
                        <p className={Styles["card-description"]}>Full Scholarship <br /> ₹ 35,000 <br /> <br /> Fully sponsor a woman's leadership journey</p>
                    </div>

                    <div className={Styles["card"]}>
                        <FaGraduationCap className={Styles["card-icon"]} />
                        <p className={Styles["card-description"]}>Multiple Scholarships <br /> ₹ 35,000 x 5 <br /> <br /> Empower five women with the tools to succeed.</p>
                    </div>
                </div>

                <div className={Styles["sub-section"]}>
                    <p className={Styles["sub-paragraph"]}>Sponsor a Woman's Future</p>
                </div>

                <div className={Styles["cta-container"]}>
                    <button onClick={() => window.open("https://zfrmz.in/PBjwmfb2OxTR0I40FP5R", "_blank")} className={Styles["cta"]}>Contribute Now</button>
                </div>

            </div>

            <div className={Styles["card-component-scholarship"]}>
                <h1 className={Styles["main-heading"]}>Apply for a Scholarship</h1>

                <div className={Styles["sub-section"]}>
                    <p className={Styles["sub-paragraph"]}>We provide full and partial scholarships to women who are passionate about their growth but do not have financial resources to go ahead. Whether you are a single mother, on a career break, or simply need the financial push to achieve your goals, Iron Lady's scholarship can help you move forward.</p>
                </div>

                <div className={Styles["sub-section"]}>
                    <p className={Styles["sub-paragraph"]} style={{ 'color': '#f52929' }}>Criteria for Eligibility:</p>
                </div>

                <div className={Styles["card-container"]}>
                    <div className={Styles["card"]}>
                        <FaChild className={Styles["card-icon"]} />
                        <p className={Styles["card-description"]}>Single mothers who are currently unemployed</p>
                    </div>

                    <div className={Styles["card"]}>
                        <FaHandHoldingUsd className={Styles["card-icon"]} />
                        <p className={Styles["card-description"]}>Women committed to their growth but lack financial resources</p>
                    </div>

                    <div className={Styles["card"]}>
                        <FaHandsHelping className={Styles["card-icon"]} />
                        <p className={Styles["card-description"]}>Women on a career break seeking to restart their journey</p>
                    </div>
                </div>

                <div className={Styles["cta-container"]}>
                    <button onClick={() => window.open("https://zfrmz.in/yz1tWPtVBLOrvx9B9goU", "_blank")} className={Styles["cta"]}>Apply Now</button>
                </div>

            </div>

            <ScholarshipTestimonial data={appLevelConstants.testimonialsScholarship} title="Testimonials" buttonId='testimonials_Home' />

            <div className={Styles["section-cta"]}>
                <span>Ready to make an Impact?</span>
                <p>
                    Contribute to Iron Lady's Pay it Forward initiative and help deserving women grow in their careers.
                </p>
                <p>
                    Be the Change. Empower Women. Shape the Future.
                </p>
                <div className={Styles["cta-container"]}>
                    <button onClick={() => window.open("https://zfrmz.in/PBjwmfb2OxTR0I40FP5R", "_blank")} className={Styles["cta"]}>Empower Women To Lead</button>
                    <button onClick={() => window.open("https://zfrmz.in/yz1tWPtVBLOrvx9B9goU", "_blank")} className={Styles["cta"]}>Apply For A Full Scholarship</button>
                </div>
            </div>

            <div className={Styles["faq-main-container"]}>
                <div className={Styles["faq-headline"]}>
                    <span>FAQ Related to Program</span>
                </div>

                <div className={Styles["faq-container"]}>
                    {appLevelConstants.scholarshipProgram.faq.map((item, index) => (
                        <Accordion
                            key={item.id}
                            expanded={expanded === `panel${item.id}`}
                            onChange={handleChange(`panel${item.id}`)}
                        >
                            <AccordionSummary
                                aria-controls={`panel${item.id}d-content`}
                                id={`panel${item.id}d-header`}
                            >
                                <Typography className={Styles["summary-typography"]}>{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography className={Styles["details-typography"]} dangerouslySetInnerHTML={{ __html: item.answer }} />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Scholarship

// import React from 'react'

// const Scholarship = () => {
//   return (
//     <div>Scholarship</div>
//   )
// }

// export default Scholarship