import Feeds from '../components/feeds/Feeds';
import EventChallengeMC from '../components/event-challenge-mc/EventChallengeMC';
import Wisdom from '../components/wisdom/Wisdom';
import InsiprationZone from '../components/inspiration-zone/InsiprationZone';
import NewsletterLH from '../components/latest-happenings-newsletter/NewsletterLH.js';

export default function LatestHappening() {
    return(
        <>
        <EventChallengeMC/>
        <NewsletterLH />
        <InsiprationZone/>
        <Wisdom/>
        <Feeds/>
        
        </>
    )
}