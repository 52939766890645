import ImpactMain from "../components/impact/ImpactMain";
import SuccessStory from "../components/impact-success-story/SuccessStory";
export default function Impact() {
  return (
    <>
      <ImpactMain />
      <SuccessStory />
    </>
  );
}
